const breakpoints = {
  iphone: "20rem", // 320px
  small:  "30rem", // 480px
  medium: "40rem", // 640px
  ipad:   "48rem", // 768px
  large:  "64rem", // 1024px
}

const mq = n => {
  const breakpointsArray = Object.keys(breakpoints).map(key => [key, breakpoints[key]])

  const [result] = breakpointsArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (min-width: ${size})`]

    return acc
  }, [])

  return result
}

export default mq
