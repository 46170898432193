import React from "react"
import { Helmet } from "react-helmet"
import { Global, css } from "@emotion/react"
import "@styles/style.scss"

import faviconSvg from "../favicon.svg"
import faviconPng from "../favicon.png"

const style = css`
  ::selection {
    color: var(--ground-color);
    background: var(--text-low);
  }
`

const Layout = ({ children }) => (
  <>
    <Helmet>
      <link rel="icon" href={faviconSvg} type="image/svg+xml" />
      <link rel="icon" href={faviconPng} type="image/png" />
    </Helmet>
    <Global styles={style} />
    {children}
  </>
)

export default Layout
